import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="Opentable — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>

		<header>
			<h1>OpenTable. Creating design systems together.</h1>
			<h3>Client of Waybury &mdash; Co-Founder & Director of Product Design</h3>
			<p>
				OpenTable was looking to update its{" "}
				<a
					href="http://opentable.com/about"
					target="_blank"
					rel="noopener noreferrer"
				>
					organizational experience
				</a>{" "}
				without the constraints of its current aesthetic. They wanted to tell
				the story of OpenTable in an approachable manner while garnering
				interest for people to work there. We ended up creating a modular,
				extensible design system which solved their immediate needs while
				creating a foundation to build upon. Three years later we were still
				working with OpenTable on new initiatives using the system we created
				together when Waybury was acquired.
			</p>
		</header>

		<section>
			<figure className="first">
				<Image alt="Org." filename="slide-opentable-org.png" />
			</figure>
		</section>

		<section>
			<figure className="first">
				<Image
					alt="Linkedin connection"
					filename="slide-opentable-linkedin.png"
				/>
			</figure>
			<article>
				<h2>Finding talent is hard</h2>
				<p>
					Convincing talent to work for you is harder. Fit and team dynamics is
					a top consideration for where people decide to work. We wanted to
					place the team of OpenTable&mdash;specifically people visitors may
					know&mdash;on the careers page. Seeing their colleagues at OpenTable
					creates an immediate connection with the company.
				</p>
			</article>
		</section>

		<section>
			<figure className="first">
				<Image
					alt="Restaurant marketing"
					filename="slide-opentable-restaurants.png"
				/>
			</figure>
			<article>
				<h2>Iterating together</h2>
				<p>
					We <strong>always</strong> advocate for collaboration, but it was
					paramount for the creation of OpenTable for Restaurants. There was
					communication across many OpenTable stakeholders including external
					copywriters and photographers&mdash;it was a lot to coordinate. We
					worked onsite for a large portion of the project&mdash;which allowed
					us to share ideas and make decisions.
				</p>
			</article>
		</section>

		<section>
			<figure className="first">
				<Image alt="Payments" filename="slide-opentable-payments.png" />
			</figure>
		</section>
		{/* <section>
			<figure className="first">
				<Image alt="Something" filename="slide-opentable-leaders.png" />
			</figure>
		</section> */}
	</Layout>
)
